<template>
  <v-dialog
    class="dialog-height"
    persistent
    scrollable
    :value="true"
    :width="width"
    @click-outside="closeNotPersistentModal"
  >
    <v-card class="df-modal">
      <v-card-title
        class="df-flex-m justify-space-between modal__title-wrapper"
      >
        <h1 class="modal__title">
          {{ title }}
        </h1>
        <v-btn icon @click="closeModal">
          <v-icon> mdi-close </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text
        class="modal__content"
        :class="withoutPadding ? 'pa-0' : ''"
        :style="scrollable ? {} : { overflowY: 'hidden' }"
      >
        <slot name="card-content"></slot>
      </v-card-text>
      <slot name="outside-content"></slot>
      <v-card-actions
        v-if="actionName"
        data-id="actions"
        class="df-flex-l justify-end"
      >
        <df-button variant="secondary" @click="closeModal">
          {{ $t('Modal.standard.cancel') }}
        </df-button>
        <df-button
          data-id="action-button"
          class="ml-0"
          :loading="loading"
          @click="action"
        >
          {{ actionName }}
        </df-button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DfButton from '@/lib/components/Button/DfButton.vue'

export default {
  name: 'DfModal',

  components: {
    DfButton,
  },

  props: {
    actionName: {
      default: '',
      type: String,
    },
    loading: {
      default: false,
      type: Boolean,
    },
    persistent: {
      default: false,
      type: Boolean,
    },
    scrollable: {
      default: true,
      type: Boolean,
    },
    title: {
      required: true,
      type: String,
    },
    width: {
      default: '640px',
      type: String,
    },
    withoutPadding: {
      default: false,
      type: Boolean,
    },
  },

  methods: {
    action() {
      this.$emit('action-click')
    },
    closeModal() {
      this.$emit('close')
    },
    closeNotPersistentModal() {
      if (!this.persistent) this.closeModal()
    },
  },
}
</script>

<style lang="scss" scoped>
.dialog-height {
  max-height: 75%;
}
.df-modal {
  .modal__title-wrapper {
    flex-wrap: nowrap;

    .modal__title {
      word-break: break-word;
    }
  }
}
.df-modal .modal__content.v-card__text {
  padding: 32px 24px;

  @include d(m) {
    padding: 16px 12px;
  }
}
::v-deep .v-dialog {
  box-shadow: none;
  border-radius: 8px;
}
::v-deep .v-card {
  .v-card__title {
    border-bottom: 1px solid #e6e9e6;
    padding: 24px;

    h1 {
      color: #1a2b46;
      font-family: 'Rubik';
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
    }
  }
  .v-card__actions {
    border-top: 1px solid #e6e9e6;
    padding: 16px 24px;
  }
}
</style>
